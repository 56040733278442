import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../Assets/img/logo.webp";
import bannerImage from "../Assets/img/bannerImg.png";
import doctor from "../Assets/img/doctor.png";
import pharmacy from "../Assets/img/phar.png";
import labImg from "../Assets/img/lab.png";
import health from "../Assets/img/health.png";
import "../StyleSheets/homestyle.css";
import PopUp from "./PopUp";

import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
interface Home {
  subscriptionStatus: boolean;
  tabs: string[];
  tabIndex: number;
  subPopUp: boolean;
  message: string;
  msisdn: string;
  onUnSubscribePressed: () => void;
  onClickTabIndex: (index: number) => void;
  closePopUp: () => void;
  onSubscribePressed: () => void;
}
const Home = (props: Home) => {
  let msisdn = localStorage.getItem("msisdn");
  return (
    <div>
      <header>
        <div className="container">
          <div className="row">
            <div className="navbarWraper">
              <div className="medLogo">
                <img src={logo} width={140} height={60} alt="logo"  />
              </div>
              <div className="navLinks">
                <ul>
                  <li>
                    {msisdn ? (
                      <a
                        className="subscribe"
                        onClick={props.onUnSubscribePressed}
                      >
                        UnSubscribe
                      </a>
                    ) : (
                      <a
                        onClick={props.onSubscribePressed}
                        className="subscribe"
                      >
                        Subscribe
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="main-container-alert-modal">
        <PopUp
          subPopUp={props.subPopUp}
          message={props.message}
          closePopUp={props.closePopUp}
        />
      </div>
      <section className="bannerSec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="bannerImg">
                {" "}
                <img src={bannerImage} alt="banner" />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="bannerHead">
                <h1>MedIQ </h1>
              </div>
              <div className="sub-heading">
                <h1>Smart Healthcare</h1>
              </div>
              <div className="bannerText">
                <p>
                  is redefining the healthcare industry and delivering
                  unparalleled service to its customers. Through medIQ app, you
                  can benefit from the one-stop-shop solution for all your
                  healthcare needs. The platform is committed to making quality
                  healthcare services affordable, accessible, convenient,
                  efficient, and cost-effective{" "}
                </p>
              </div>
              <div className="bannerBtn">
                <a
                  href="https://onelink.to/cy46vk"
                  target="_blank"
                  className="contactBtn"
                >
                  Download App
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.24408 5.74408C8.56951 5.41864 9.09715 5.41864 9.42259 5.74408L13.5893 9.91074C13.9147 10.2362 13.9147 10.7638 13.5893 11.0893L9.42259 15.2559C9.09715 15.5814 8.56951 15.5814 8.24408 15.2559C7.91864 14.9305 7.91864 14.4028 8.24408 14.0774L11.8215 10.5L8.24408 6.92259C7.91864 6.59715 7.91864 6.06951 8.24408 5.74408Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="offerSec">
        <div className="offerSec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="service">
                  <div className="service-text">
                    medIQ Service for Jazz users
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="doctor-section">
        <div className="doctor-content doc">
          <div className="doc-text">
            <div className="doc-heading">Doctor Teleconsultations</div>
            <div className="doc-sub">
              Why go to a hospital when you can get connected with a doctor on
              the phone?
            </div>
          </div>

          <div className="doc-image">
            <img src={doctor} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Avail FREE video consultation on medIQ mobile app with GP
                Doctors anywhere anytime.
              </p>
              <br />
              <p>
                Doctors from over 32 Specialities at discounted rates are
                available for consultation at the point of need.
              </p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/cy46vk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>
        <div className="doctor-content ">
          <div className="doc-text">
            <div className="doc-heading">Pharmacy</div>
            <div className="doc-sub">
              Get Your Medicines in Time at your Doorstep
            </div>
          </div>

          <div className="doc-image">
            <img src={pharmacy} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Order through medIQ App and get medicines delivered at your
                home.
              </p>
              <br />
              <p>
                Patients can choose from a wide range of products for home
                medicine delivery.{" "}
              </p>
              <p>Genuine medicines are available at discounted rates.</p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/cy46vk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>
        <div className="doctor-content doc">
          <div className="doc-text">
            <div className="doc-heading">Labs</div>
            <div className="doc-sub">
              Why go to the lab when you can get the lab to come to your home?
            </div>
          </div>

          <div className="doc-image">
            <img src={labImg} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Place an order on the medIQ app and get your lab sample
                collected at home.
              </p>
              <br />
              <p>
                Get laboratory tests done easily with our Home Sample
                Collection.
              </p>
              <p>All leading laboratories of Pakistan are available .</p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/cy46vk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>

        <div className="doctor-content ">
          <div className="doc-text">
            <div className="doc-heading">Health & Wellness</div>
            <div className="doc-sub">Stay active, health and happy</div>
          </div>

          <div className="doc-image">
            <img src={health} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Get daily health tips, diet plans, nutritional guidelines, home
                remedies, and much more, designed to support overall health and
                well-being.
              </p>
              <br />
              <p>
                Keep up to date with the latest trends and developments with our
                informative health blogs and engaging videos.
              </p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/cy46vk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>
      </div>

      {/*footer */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="footer-link">
                <div className="foo-heading">Social</div>

                <div className="social-link">
                  <div className="icon">
                    <a
                      href="https://www.youtube.com/c/mediqsmarthealthcare"
                      className="f-link"
                    >
                      <span> Youtube</span> <FontAwesomeIcon icon={faYoutube} />{" "}
                    </a>
                  </div>

                  <div className="icon">
                    <a
                      href="https://www.instagram.com/mediq_smart_healthcare/"
                      className="f-link"
                    >
                      {" "}
                      <span>Instagram</span>{" "}
                      <FontAwesomeIcon icon={faInstagram} />{" "}
                    </a>
                  </div>

                  <div className="icon">
                    <a href="https://twitter.com/ShcMediq" className="f-link">
                      {" "}
                      <span> Twitter</span> <FontAwesomeIcon icon={faTwitter} />{" "}
                    </a>
                  </div>

                  <div className="icon">
                    <a
                      href="https://web.facebook.com/mediq.smarthealthcare"
                      className="f-link"
                    >
                      {" "}
                      <span> Facebook</span>{" "}
                      <FontAwesomeIcon icon={faFacebook} />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="footer-link">
                <div className="foo-heading">Contact Us</div>

                <div className="contact-text">
                  <p>UAN: 051 111 377 377 </p>
                  <p>Phone: 051 877 3377 </p>
                  <p>
                    Web:{" "}
                    <a href="#" className="mediqhref">
                      www.mediq.com.pk
                    </a>
                  </p>
                </div>
                <div className="footer-text">© 2024. All rights reserved.</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
