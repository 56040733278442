import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import { encode } from "base-64";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Awareness from "../Components/Awareness";

type Props = {
  token: string | null;
};

let adNetwork: string = "";
const AwarenessScreen = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";

  useEffect(() => {
    if (props.token) {
      checkUtm();
      getFlow();
    }
  }, [props.token]);

  const getFlow = async () => {
    try {
      // let data = {
      //   status: 0,
      //   result: {
      //     isSpecialFlow: true,
      //     fastFlow: true,
      //     msisdn: "3365413589",
      //     otp: "1234",
      //     milliseconds: 2000,
      //     signIn: false,
      //   },
      // };

      let response = await fetchApiGet(
        props.token,
        `${ApiNames.awarenessFlow}?utm_source=${utm_source}&network=jazz`
      );
      // let response = data;
      // if (response.status === 0) {
      //   if (response.result.isSpecialFlow) {
      //     subscribe(
      //       response.result.msisdn,
      //       response.result.otp,
      //       response.result.fastFlow
      //         ? ApiNames.subscribeFastFlow
      //         : ApiNames.subscribe
      //     );
      //   } else {
      //     navigateToHome();
      //   }
      // }
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("subscribe", "econceptions");
        } else {
          tagManagerEvents("subscribe", utm_source);
        }
        moveToHome(response.msisdn);
      } else {
        navigateToHome();
      }
    } catch (error) {
      navigateToHome();

      console.log("get flow error ::", error);
    }
  };

  const checkUtm = async () => {
    try {
      let response = await fetchApiGet(props.token, ApiNames.utmList);
      if (response.status === 200) {
        {
          response.response.map((e: any) => {
            if (utm_source === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const subscribe = async (_msisdn: string, _otp: string, endPoint: string) => {
    try {
      let data = {
        msisdn: _msisdn,
        otp: _otp,
        utm_source: utm_source,
      };
      let response = await fetchApiPost(endPoint, props.token, data);
      if (response.status === 0 || response.status === 1) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("subscribe", "econceptions");
        } else {
          tagManagerEvents("subscribe", utm_source);
        }
        moveToHome(_msisdn);
      } else {
        navigateToHome();
      }
    } catch (error) {
      navigateToHome();
    }
  };

  const moveToHome = (_msisdn: string) => {
    let url = window.location.origin;
    let encodedMsisdn = encode(_msisdn);
    url = `${url}/home?user=${encodedMsisdn}`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/home`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };
  return <Awareness />;
};

export default AwarenessScreen;
