import React, { useEffect, useState } from "react";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import JazzWorld from "../Components/JazzWorld";

type Props = {
  token: null;
};

let encodeMsisdn = "";

const JazzWorldScreen = (props: Props) => {
  const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement>[]>(
    [
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
    ]
  );
  const [otp, setOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  const [show, setShow] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [msisdn, setMsisdn] = useState("");
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.token) {
      handleMsisdnGet();
    }
  }, [props.token]);
  useEffect(() => {
    setSeconds(30);
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  }, [seconds]);

  // const handleMsisdnGet = () => {
  //   const url = new URL(window.location.href);
  //   const searchParams = new URLSearchParams(url.search);
  //   const _encodedMsisdn: string | null = searchParams.get("msisdn");
  //   handeldecryptMsisdn(_encodedMsisdn);
  // };

  const handleMsisdnGet = () => {
    let url = window.location.href;
    const msisdnMatch: any = url.match(/[?&]msisdn=([^&]+)/);
    if (msisdnMatch) {
      const msisdnValue = msisdnMatch[1];
      encodeMsisdn = msisdnValue;
      handeldecryptMsisdn(msisdnValue);
    } else {
      console.log("msisdn not found");
      return null;
    }
  };
  const handeldecryptMsisdn = async (encodeMsisdn: string | null) => {
    try {
      let data = {
        msisdn: encodeMsisdn,
      };
      let response = await fetchApiPost(ApiNames.decrypt, props.token, data);
      if (
        response.status === 0 &&
        response.result.toLowerCase() === "success"
      ) {
        setMsisdn(response.msisdn);
        fetchCheckUser(response.msisdn);
      } else {
      }
    } catch (error) {
      console.log("handeldecryptMsisdn" + error);
    }
  };

  const fetchCheckUser = async (_msisdn: any) => {
    try {
      if (_msisdn) {
        let endPoint = `${ApiNames.checkUser}/${_msisdn}`;
        let response = await fetchApiGet(props.token, endPoint);
        if (response.status === 0) {
          setIsActiveUser(true);
        } else {
          setIsActiveUser(false);
        }
      }
    } catch (error) {
      console.log("fetchCheck user error", error);
    }
  };

  const sendOTP = async () => {
    try {
      let data = {
        msisdn: msisdn,
      };
      let response = await fetchApiPost(ApiNames.sendOtp, props.token, data);
      if (response.status === 0) {
      } else {
        setIsError(true);
        setErrorMessage("Unable to send OTP, Please try again.");
      }
    } catch (error) {
      console.log("sendOTP", error);
      setIsError(true);
      setErrorMessage("Unable to send OTP, Please try again.");
    }
  };
  const resendOTP = async () => {
    try {
      setOtp({
        one: "",
        two: "",
        three: "",
        four: "",
      });
      setSeconds(30);
      setIsLoading(false);
      setIsError(false);
      let data = {
        msisdn: msisdn,
      };
      let response = await fetchApiPost(ApiNames.sendOtp, props.token, data);
      if (response.status === 0) {
      } else {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage("Unable to send OTP, Please try again.");
      }
    } catch (error) {
      console.log("sendOTP", error);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Unable to send OTP, Please try again.");
    }
  };

  const subscribe = async () => {
    try {
      setIsLoading(true);
      let _otp: string = otp.one + otp.two + otp.three + otp.four;
      let data = {
        msisdn: msisdn,
        otp: _otp,
        utm_source: "",
        jazzWorld: true,
      };
      let response = await fetchApiPost(ApiNames.subscribe, props.token, data);
      if (response.status === 0) {
        setIsActiveUser(true);
        handleClose();
      } else {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage("Something went wrong please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("subscribe", error);
      setIsError(true);
      setErrorMessage("Something went wrong please try again.");
    }
  };
  const onResendOTPPressed = () => {
    resendOTP();
  };
  const handleOtpInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value;
    if (!isNaN(newValue as any) && newValue !== " ") {
      setIsError(false);
      setOtp({
        ...otp,
        [e.target.name]: newValue,
      });
      if (e.target.value.length === 1) {
        const nextInput = inputRef[index + 1];
        if (nextInput?.current) {
          nextInput.current.focus();
        }
      }
    }
  };
  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (e.keyCode === 8) {
      setIsError(false);
      const target = e.target as HTMLInputElement;
      if (target.value.length === 0 && index !== 0) {
        (inputRef[index - 1].current as HTMLInputElement).focus();
      }
    }
  };
  const handleClose = () => {
    setOtp({
      one: "",
      two: "",
      three: "",
      four: "",
    });
    setIsError(false);
    setShow(false);
  };
  const handleShow = () => {
    if (isActiveUser) {
      setShow(false);
    } else {
      sendOTP();
      setShow(true);
    }
  };
  const onUnSubscribe = async () => {
    try {
      setIsLoading(false);
      let data = {
        msisdn: msisdn,
      };
      let response = await fetchApiPost(
        ApiNames.unsubscribe,
        props.token,
        data
      );
      if (response.status === 0) {
        if (response.result === "Subscriber UnSubscribed.") {
          setIsActiveUser(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("onunsubscribe pressed ::", error);
    }
  };
  const handleExternalNavigation = () => {
    window.open(
      `https://app.mediq.com.pk/doctor-consultation?phone_number=${encodeMsisdn}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <JazzWorld
        inputRef={inputRef}
        otp={otp}
        handleOtpInput={handleOtpInput}
        handleBackspace={handleBackspace}
        msisdn={msisdn}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        seconds={seconds}
        onResendOTPPressed={onResendOTPPressed}
        subscribe={subscribe}
        isError={isError}
        errorMessage={errorMessage}
        isActiveUser={isActiveUser}
        onUnSubscribe={onUnSubscribe}
        isLoading={isLoading}
        encodeMsisdn={encodeMsisdn}
        handleExternalNavigation={handleExternalNavigation}
      />
    </>
  );
};

export default JazzWorldScreen;
