const ApiNames = {
  // generateToken: "/api/generateTokenWeb",
  authentication: "authenticateWeb",
  sendOtp: "user/signupWeb",
  subscribe: "user/subscribeWeb",
  unsubscribe: "user/unsubscribeWeb",
  checkUser: "user/checkUser",
  checkOperator: "user/checkOperator",
  getFlow: "user/getFlow",
  awarenessFlow: "user/awarenessFlow",
  verifyOtp: "user/verifyOtpWeb",
  subscribeFastFlow: "user/ffSubscribe",
  utmList: "user/utmList",
  decrypt: "user/decrypt",
};
export default ApiNames;
